import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GrupoServices {
    private url: string;
    private headers = new HttpHeaders({ 'Accept': 'application/json' });

    constructor(private _http: HttpClient) {
        this.url = environment.urlLambda;
    }

    getGrupos(iduser: number, estado: boolean) {
        let serviceURL = `${this.url}services.grupos/grupos/${iduser}/?activo=${estado}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getControlGrupos(idgroup: number) {
        let serviceURL = `${this.url}services.grupos/controlgrupos/${idgroup}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getGraficaBuscamina(idg: number, fecha: string) {
        let serviceURL = `${this.url}services.grupos/gbm/${idg}/?mes=${fecha}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getAdherenciaGeneral(idgrupo: number) {
        let serviceURL = `${this.url}services.grupos/adherencia/general/${idgrupo}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getAdherenciaGeneralPorPaciente(idgrupo: number, idpaciente: number) {
        let serviceURL = `${this.url}services.grupos/adherencia/general-por-paciente/${idgrupo}/${idpaciente}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getAdherenciaDia(idgrupo: number) {
        let serviceURL = `${this.url}services.grupos/adherencia/dia/${idgrupo}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getMetricaGroup(idgrupo: number) {
        let serviceURL = `${this.url}services.grupos/metricas/group/${idgrupo}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getMetaBeneficiario(idgrupo: number) {
        let serviceURL = `${this.url}services.grupos/beneficiarios/meta/${idgrupo}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }

    getAdherenciaByFilter(idgrupo: number, adherencia: string, type?: string) {
        let serviceURL = `${this.url}services.grupos/adherencia/filter/${idgrupo}/${adherencia}/${type}`;
        return this._http.get(serviceURL, { headers: this.headers });
    }
}