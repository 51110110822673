import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  endPoint = environment.urlUploadLambda;
  formData: FormData = new FormData();
  private urlApi: string;
  private headers = new HttpHeaders({'Accept': 'application/json' });

  constructor(
    private _http: HttpClient,
  ) {
    this.urlApi = environment.urlUploadLambda;
  }

  saveImage(imageFile: File, folder?: string): any {
    if (!this.endPoint) {
      throw new Error('URL de subida de imagen no es válida');
    }
    if (folder) {
      this.endPoint += '?folder=' + folder;
    }

    if (imageFile) {
      this.formData = new FormData();
      let file = imageFile;
      this.formData.append('file', file);
      const req = new HttpRequest('POST', this.endPoint, this.formData);
      return this._http.request(req);
    } else {
      throw new Error('Imagen inválida');
    }
  }

  uploadImage(dataurl): any {
    if (!this.endPoint) {
      throw new Error('URL de subida de imagen no es válida');
    }

    if (dataurl) {
      this.formData = new FormData();
      let file = this.dataURLtoFile(dataurl, 'file');
      this.formData.append('file', file);
      const req = new HttpRequest('POST', this.endPoint, this.formData);
      return this._http.request(req);
    } else {
      throw new Error('Imagen inválida');
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getUploadURL(folder: string) {
    return this._http.get(`${this.urlApi}/uploadURL?folder=${folder}`, { headers: this.headers });
  }

  uploadfileAWSS3(fileuploadurl, contenttype, file) {
    let headers = new HttpHeaders({'Content-Type': contenttype});
    return this._http.put(fileuploadurl, file, {headers: headers});
  }

}
