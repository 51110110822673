import { Injectable } from '@angular/core';
import { KeycloakInstance } from 'keycloak-js';
import { environment } from 'src/environments/environment';
declare let Keycloak: any;

@Injectable({
  providedIn: 'root'
})
export class KeycloakSecurityService {
  public userProfile: any;
  public kc: KeycloakInstance;
  public url: string;

  constructor() {
    this.url = `${environment.urlKeycloak}`;
  }

  public async init() {
    this.kc = new Keycloak({
      url: environment.urlKeycloak,
      realm: environment.realm,
      clientId: environment.clientId
    });

    try {
      await this.kc.init({
        onLoad: 'login-required',
      });
    } catch (e) {
      console.log(e);
    }
  }
}