<div *ngIf="rol?.length" class="options">
    <div class="options__item">
        <a *ngIf="(!(keycloak.is_superadmin | async))" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/inicio']" routerLinkActive="router-link-active">
            <span class="icon fas fa-home fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_inicio' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_inicio' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_superadmin | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/superadmin/organizaciones']" routerLinkActive="router-link-active">
            <span class="icon fas fa-sitemap fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_organizaciones' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_organizaciones' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_superadmin | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/superadmin/versiones-apps']">
            <span class="icon fas fa-mobile fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_versions_apps' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_versions_apps' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_superadmin | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/superadmin/terminos-condiciones']">
            <span class="icon fas fa-file-invoice fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_tc' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_tc' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_superadmin | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/superadmin/encuestas']">
            <span class="icon fas fa-question fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_survey' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_survey' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
    </div>

    <div class="options__item">
        <div *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" class="dividir"></div>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/especialidades']">
            <span class="icon fas fa-user-nurse fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_especialidades' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_especialidades' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/categorias']">
            <span class="icon fas fa-list-ul fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_categorias' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_categorias' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/etiquetas']">
            <span class="icon fas fa-tags fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_etiquetas' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_etiquetas' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/templates']">
            <span class="icon fas fa-mail-bulk fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_correos' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_correos' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/profesionales']">
            <span class="icon fas fa-user-md fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_profesionales' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_profesionales' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_upi | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/upi/']">
            <span class="icon fas fa-user-tag fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_beneficiarios' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_survey_and_patients' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_profesional | async) || (keycloak.is_upi | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/pacientes']">
            <span class="icon fas fa-file-medical fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_patients' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_patients' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async) || (keycloak.is_profesional | async) || (keycloak.is_upi | async)"
            az-tooltip class="options__link" onclick="hiddeNav();" [routerLink]="['/admin/beneficiario']">
            <span class="icon fas fa-user-injured fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_beneficiarios' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_beneficiarios' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)" az-tooltip class="options__link"
            onclick="hiddeNav();" [routerLink]="['/admin/programas']">
            <span class="icon fas fa-th fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_programas' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_programas' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async)" az-tooltip class="options__link" onclick="hiddeNav();"
            [routerLink]="['/admin/personalizar']">
            <span class="icon fas fa-pencil-ruler fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_personalizar' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_personalizar' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async) || (keycloak.is_profesional | async) || (keycloak.is_upi | async)"
            az-tooltip class="options__link" onclick="hiddeNav();" [routerLink]="['/admin/alertas']">
            <span class="icon fas fa-broadcast-tower fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_alerts' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_alerts' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
        <a *ngIf="(keycloak.is_propietario | async) || (keycloak.is_admin | async)"
            az-tooltip class="options__link" onclick="hiddeNav();" [routerLink]="['/admin/articulos']">
            <span class="icon fas fa-newspaper fa-lg"></span>
            <p>{{ BASE_TRANSLATE + '.navigation.op_articles' | translate }}</p>
            <az-tooltip [azTooltipText]="BASE_TRANSLATE + '.navigation.op_articles' | translate"
                azTooltipPosition="right"></az-tooltip>
        </a>
    </div>
</div>