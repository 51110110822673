import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgEventBus } from 'ng-event-bus';
import { PersonalizarService } from 'src/app/admin/services/personalizar.service';
import { Profile } from 'src/app/models/profile.model';
import { AuthenticatedKeycloak } from 'src/app/services/authenticated-keycloak.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../services/profile.service';
import { CambiarClaveComponent } from '../cambiar-clave/cambiar-clave.component';
import { ProfileComponent } from '../profile/profile.component';
import * as moment from 'moment';
import { CambiarRolComponent } from '../cambiar-rol/cambiar-rol.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    providers: [AuthenticatedKeycloak, PersonalizarService]
})
export class NavbarComponent implements OnInit {
    public readonly BASE_TRANSLATE = "shared";

    nombre: string;
    cargo: boolean;
    logo: string = "";
    imagen: string;
    @Input() idGrupo: number;
    @Input() showNavLateral: any;
    profile: any = {};
    mobile: boolean;

    is_superadmin: boolean = false;
    is_paciente: boolean = false;
    is_admin: boolean = false;

    device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    spinnerConf: { background: string, icon: string } = { background: '#000', icon: '#CCC' };

    avatar: string = "";

    constructor(
        public keycloak: AuthenticatedKeycloak,
        private _sp: PersonalizarService,
        private eventbus: NgEventBus,
        private dialog: MatDialog,
        private profileService: ProfileService,
        private _location: Location
    ) {
        this.mobile = this.device;
    }

    ngOnInit() {
        if (this.keycloak.roles.length <= 1) {
            this.keycloak.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === 'paciente') {
                    this.keycloak.isPaciente().then(res => this.is_paciente = res);
                }
            });
        }

        this.keycloak.getUserObjectMe().then(rest => {
            let res = { picture: "", me: rest };

            if(res.me.perfil){
                this.is_superadmin = res.me.perfil.filter((item) => item.nombre === "super_admin").length === 1;
                this.is_admin = res.me.perfil.filter((item) => item.nombre === "admin").length === 1;
    
                if (res.me.perfil.filter((item) => item.nombre === "paciente" || item.nombre === "super_admin").length === 0) {
                    this.getLogo();
                    this.getAvatarUser();
                    this.addPaletaColor();
                }
            }

            this.profileService.getDatos(this.is_paciente).subscribe((result: any) => {
                res.me.apellidoMaterno = result.retObject.apellidomaterno;
                res.me.apellidoPaterno = result.retObject.apellidopaterno;
                res.me.nombre = result.retObject.nombre;
                res.me.email = result.retObject.email;
                res.me.genero = result.retObject.genero;
                res.me.fechaNacimiento = moment(result.retObject.pacfechanacimiento || new Date()).format("YYYY-MM-DD");
                res.me.altura = result.retObject.altura;
                res.me.atleta = result.retObject.is_atleta;
                res.me.gestante = result.retObject.is_gestante;
                this.profile = new Profile(res);
            }, (err) => {
                console.log(err);
            });
        });

        this.getInfoClient();

        this.eventbus.on('avatar:user').subscribe((res: any) => {
            if (res._data) {
                this.avatar = res._data.avatar;
            }
        });

        this.eventbus.on('info-profile:user').subscribe((res: any) => {
            if (res._data) {
                this.profile.name = res._data.user.nombre;
                this.profile.apellidopaterno = res._data.user.apellidopaterno;
            }
        });
        for (const e in localStorage) {
            if (String(e).substring(0, 13) === "azh_buscamina") {
                localStorage.removeItem(e)
            }
        }
    }

    get isRoling(): boolean {
        return localStorage.getItem(`azh_${environment.name}_token__from_support`) ? true : false;
    }

    ToggleNav() {
        const NAV_LATERAL = document.querySelector("#showMenuLat");

        if (NAV_LATERAL) {
            if (NAV_LATERAL.classList.contains("nav-lateral-app--collapse")) {
                NAV_LATERAL.classList.remove("nav-lateral-app--collapse");
                document.documentElement.style.setProperty('--width-nla', `210px`);
            } else {
                NAV_LATERAL.classList.add("nav-lateral-app--collapse");
                document.documentElement.style.setProperty('--width-nla', `80px`);
            }
        }
    }

    openChangePasswordModal() {
        this.dialog.open(CambiarClaveComponent, {
            data: {}
        });
    }

    openChangerol() {
        this.dialog.open(CambiarRolComponent, {
            data: {}
        });
    }

    exitChangeRol() {
        localStorage.removeItem(`azh_${environment.name}_token__from_support`);
        localStorage.removeItem(`azh_${environment.name}_roles_from_support`);
        this._location.historyGo(0);
    }

    openProfileModal() {
        this.dialog.open(ProfileComponent, {
            data: {
                info: { ...this.profile.me },
                avatar: this.avatar,
                isPaciente: this.is_paciente
            }
        });
    }

    getAvatarUser() {
        this.profileService.getAvatar().subscribe((res: { avatar: string }) => {
            this.avatar = res.avatar;
        });
    }

    logout() {
        this.keycloak.logout();
    }

    getInfoClient() {
        this._sp.infoClient().subscribe((res: any) => {
            if (res.retObject.length) {
                localStorage.setItem(`azh_${environment.name}-contract`, res.retObject[0].idcontract);
            }
        }, err => {
            this.logo = '';
            if (err.status == 403 && localStorage.getItem(`azh_${environment.name}_token__from_support`)) {
                this.exitChangeRol();
                return;
            }
        });
    }

    getLogo() {
        if (this.is_superadmin === false) {
            this.keycloak.getImageClinica().subscribe((res: any) => {
                this.logo = res.logo != null ? res.logo === '' ? '' : res.logo : '';
            }, err => {
                this.logo = '';
                console.log(err);
            });
        }
    }

    _darkMode: boolean = false;
    darkMode() {
        if (this._darkMode === false) {
            this._darkMode = true;
            document.documentElement.style.setProperty('--texto', `#f4f4f4`);
            document.documentElement.style.setProperty('--fondo', `#292929`);
            document.documentElement.style.setProperty('--barra', `#232223`);
            document.documentElement.style.setProperty('--background-rgba', `rgba(0,0,0,.8)`);
            document.documentElement.style.setProperty('--fondo-emisor', `#000000`);
            document.documentElement.style.setProperty('--fondo-receptor', `#444444`);
            document.documentElement.style.setProperty('--text-emisor', `#ffffff`);
            document.documentElement.style.setProperty('--text-receptor', `#ffffff`);
            document.documentElement.style.setProperty('--calendar-color', `invert(0.8)`);
            window.localStorage.setItem("DarkMode", "true");
        } else {
            this._darkMode = false;
            document.documentElement.style.setProperty('--texto', `#2C2633`);
            document.documentElement.style.setProperty('--fondo', `#EEEEEE`);
            document.documentElement.style.setProperty('--barra', `#FFFFFF`);
            document.documentElement.style.setProperty('--background-rgba', `rgba(255,255,255,.8)`);
            document.documentElement.style.setProperty('--fondo-emisor', `#8F8F8F`);
            document.documentElement.style.setProperty('--fondo-receptor', `#e2e2e2`);
            document.documentElement.style.setProperty('--text-emisor', `#ffffff`);
            document.documentElement.style.setProperty('--text-receptor', `#2c2633`);
            document.documentElement.style.setProperty('--calendar-color', `invert(0)`);
            window.localStorage.setItem("DarkMode", "false");
        }
    }

    addPaletaColor() {
        if (this.is_superadmin === false) {
            this._sp.getPaletaColor().subscribe((res: any) => {
                if (!res.paleta?.length) {
                    document.documentElement.style.setProperty('--primario', '#511e84');
                    document.documentElement.style.setProperty('--secundario', '#ff7d54');
                    document.documentElement.style.setProperty('--terciario', '#a87fd2');
                    document.documentElement.style.setProperty('--texto', '#2c2633');
                    document.documentElement.style.setProperty('--texto-boton', `#FFFFFF`);
                    document.documentElement.style.setProperty('--fondo', '#eeeeee');
                    document.documentElement.style.setProperty('--barra', '#ffffff');
                    document.documentElement.style.setProperty('--background-rgba', `rgba(255,255,255,.8)`);

                    if (window.localStorage.getItem("DarkMode") === "true") {
                        document.documentElement.style.setProperty('--texto', `#f4f4f4`);
                        document.documentElement.style.setProperty('--fondo', `#292929`);
                        document.documentElement.style.setProperty('--barra', `#232223`);
                        document.documentElement.style.setProperty('--background-rgba', `rgba(0,0,0,.8)`);
                        document.documentElement.style.setProperty('--fondo-emisor', `#000000`);
                        document.documentElement.style.setProperty('--fondo-receptor', `#444444`);
                        document.documentElement.style.setProperty('--text-emisor', `#ffffff`);
                        document.documentElement.style.setProperty('--text-receptor', `#ffffff`);
                        document.documentElement.style.setProperty('--calendar-color', `invert(0.8)`);
                        this._darkMode = true;
                    }
                } else {
                    if (res.paleta.length) {
                        let pc = res.paleta[0];
                        document.documentElement.style.setProperty('--primario', `${pc.primario}`);
                        document.documentElement.style.setProperty('--secundario', `${pc.secundario}`);
                        document.documentElement.style.setProperty('--terciario', `${pc.terciario}`);
                        document.documentElement.style.setProperty('--texto', `${pc.texto}`);
                        document.documentElement.style.setProperty('--texto-boton', `${pc.textoboton}`);
                        document.documentElement.style.setProperty('--fondo', `${pc.fondo}`);
                        document.documentElement.style.setProperty('--barra', `${pc.barra}`);
                        document.documentElement.style.setProperty('--background-rgba', `rgba(255,255,255,.75)`);

                        if (window.localStorage.getItem("DarkMode") === "true") {
                            document.documentElement.style.setProperty('--texto', `#f4f4f4`);
                            document.documentElement.style.setProperty('--fondo', `#292929`);
                            document.documentElement.style.setProperty('--barra', `#232223`);
                            document.documentElement.style.setProperty('--background-rgba', `rgba(0,0,0,.75)`);
                            document.documentElement.style.setProperty('--fondo-emisor', `#8F8F8F`);
                            document.documentElement.style.setProperty('--fondo-receptor', `#e2e2e2`);
                            document.documentElement.style.setProperty('--text-emisor', `#ffffff`);
                            document.documentElement.style.setProperty('--text-receptor', `#2c2633`);
                            document.documentElement.style.setProperty('--calendar-color', `invert(0.8)`);
                            this._darkMode = true;
                        }
                    }
                }
            }, err => {
                console.log(err);
            });
        }
    }
}

