import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class PersonalizarService {
    private headers = new HttpHeaders({ 'Accept': 'application/json' });
    private url: string;
    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.urlLambda;
    }

    updateLogo(data: any) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let serviceURL = `${this.url}services.cliente/updatelogo`;
        return this._http.post(serviceURL, data, { headers: this.headers } );
    }

    infoClient() {
        let serviceURL = `${this.url}services.cliente/info-client`;
        return this._http.get(serviceURL, { headers: this.headers } );
    }

    updatePaleta(data: any) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let serviceURL = `${this.url}services.cliente/updatePaleta`;
        return this._http.post(serviceURL, data, { headers: this.headers } );
    }

    getPaletaColor() {
        let serviceURL = `${this.url}services.cliente/getpaleta`;
        return this._http.get(serviceURL, { headers: this.headers });
    }
}