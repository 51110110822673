import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import * as _ from 'lodash';
import { KeycloakSecurityService } from './keycloak-security.service';
import { AuthenticatedKeycloak } from './authenticated-keycloak.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  constructor(
    private keycloakService: KeycloakSecurityService,
    private authenticatedKeycloak: AuthenticatedKeycloak
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(`azh_${environment.name}_token__from_support`) ?? this.keycloakService.kc.token;

    if (!_.isUndefined(accessToken) && !_.isEmpty(accessToken)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.handleRefreshToken(() => {
          this.refreshTokenInProgress = false;
        });
      }
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => this.handleAuthUnauthorized(event)),
      catchError((error: HttpErrorResponse) => this.handleAuthError(error))
    );
  }

  handleRefreshToken(callback) {
    const isTokenExpired = this.keycloakService.kc.isTokenExpired();

    if (isTokenExpired) {
      this.keycloakService.kc.updateToken(0).then(() => {
        callback();
      });
    } else {
      callback();
    }
  }

  private handleAuthUnauthorized(event: HttpEvent<any>): any {
    return event;
  }

  private handleAuthError(errorResponse: HttpErrorResponse): Observable<any> {
    const code = _.get(errorResponse, 'status', HttpStatusCode.Ok);

    if (_.eq(code, HttpStatusCode.Unauthorized) || _.eq(code, HttpStatusCode.InternalServerError)) {
      this.authenticatedKeycloak.logout();
    }

    return throwError(errorResponse);
  }
}
