import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { NgEventBus } from 'ng-event-bus';
import { Observable, of } from 'rxjs';
import { JsonResult } from 'src/app/models/json-result.model';
import { TableAction, TableActionEvent, TableField } from 'src/app/shared/az-table';
import { environment } from 'src/environments/environment';
import { crearGlicoComponent } from './form/crear-glicosilada.component';
import { Glicosilada } from '../../models/glicosilada.model';
import { GlicosiladaService } from '../../services/glicosilada.service';
import { map } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { AuthenticatedKeycloak } from 'src/app/services/authenticated-keycloak.service';
import { ValidatorsUtils } from 'src/app/shared/utils/validators.util';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    providers: [GlicosiladaService]
})

export class ContainerComponent implements OnInit {
    public readonly BASE_TRANSLATE = "admin.glicosilada.list";
    public readonly BASE_TRANSLATE_SHARED = "shared";

    columns: any[] = [];
    rows$!: Observable<any[]>;
    customActions: TableAction[] = [];
    selectedEstado = 'true';
    revision: any = 'true';
    glicosiladas: Glicosilada[] = new Array<Glicosilada>();
    loading: boolean = true;

    itemsForRows: number = 500;
    currentPage: number = 1;

    start: number = 0;
    end: number = 0;
    total: number = 0;
    totalPage: number = 1;
    iduser_pro: string = "";
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ContainerComponent>,
        @Inject(MAT_DIALOG_DATA) public selectedItems: any,
        private adapter: DateAdapter<any>,
        public eventbus: NgEventBus,
        private _glicosiladaservice: GlicosiladaService,
        private alerts: SnackBarService,
        public keycloak: AuthenticatedKeycloak,
        private validatorsUtils: ValidatorsUtils,
    ) {
        moment.locale(localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) : 'es');
        eventbus.on("translate:change").subscribe((t: any) => {
            moment.locale(t._data);
        });
        this.adapter.setLocale(localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) : 'es');
    }

    ngOnInit() {
        this.customActions = this.getTableActions();
        this.columns = this.getTableColumns();
        this.getItemsByEstado();
        this.keycloak.getUserObjectMe().then(res => {
            this.iduser_pro = res.id;
        }).catch(error => {
            console.log(error);
        });
    }
    private getTableActions(): TableAction[] {
        return this.validatorsUtils.getActionsToggleAndEdit(this.BASE_TRANSLATE);
    }
    private getTableColumns(): TableField[] {
        return [
            {
                key: 'day_test_format',
                label: 'Dia',
                textAlign: "left",
                translation: `${this.BASE_TRANSLATE}.list.th_day`,
            },
            {
                key: 'value_test_format',
                label: 'Valor',
                textAlign: "left",
                translation: `${this.BASE_TRANSLATE}.list.th_value`,
            }
        ] as TableField[];
    }
    onActionClick(event: TableActionEvent) {
        if (event.action.name === "editar") {
            this.openDialogCrear(false, event.row.item)
        }
        if (event.action.name === "toggleCheck") {
            this.glicosiladas[event.row.index].active = !event.row.item.active;
            this.toggleEstado(event.row.item.idglicosilada_history);
        }
    }
    selectFiltros() {
        this.getItemsByEstado();
    }
    getItemsByEstado() {
        this.loading = true;
        this._glicosiladaservice.get(this.selectedItems.idpaciente, this.currentPage, this.itemsForRows, this.revision)
            .subscribe(
                (trae: JsonResult) => {
                    this.glicosiladas = trae.retObject.data;
                    let result: Observable<any[]> = of(this.glicosiladas);
                    this.ResolveData(result);
                    this.start = trae.retObject.from;
                    this.end = trae.retObject.to;
                    this.total = trae.retObject.total;
                    this.totalPage = trae.retObject.last_page;
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    console.error(error);
                    console.error(error.status);
                }, () => {
                    this.loading = false;
                });
    }
    ResolveData(result: Observable<any[]>): void {
        this.rows$ = result.pipe(
            map((result2) => {
                return result2.map((trae) => {
                    trae.value_test_format = `${trae.value_test}%`
                    trae.actions = {
                        togglecheck: true,
                        editar: true,
                    }
                    return trae;
                });
            })
        );
    }
    toggleEstado(idedit: number) {
        this._glicosiladaservice.toggleEstado(idedit).subscribe((result: JsonResult) => {
            this.eventbus.cast('glicosilada:change', true);
            this.alerts.success(result.retMessage);
        }, error => {
            console.log(error);
            console.log(error.status);
        });
    }
    filterBySearch(event: any, typesearch: string) {
        let array: any[] = [];
        this.glicosiladas.forEach(x2 => {
            let __filter = String(x2[typesearch]).toLowerCase().includes(event.target.value.toLowerCase());
            if (__filter === true) {
                array.push(x2);
            }
        });

        let results: Observable<any[]> = of(array);
        this.ResolveData(results);
    }
    openDialogCrear(type: boolean, data?: any): void {
        let set = {
            day_test: "",
            value_test: ""
        }
        if (!type) {
            set = { ...data };
            set.day_test = `${moment(set.day_test).format("YYYY-MM-DD")}`;
        }
        const dialogRef = this.dialog.open(crearGlicoComponent, {
            data: {
                data: set,
                type: type,
                idpaciente: this.selectedItems.idpaciente,
                idprofesional: this.iduser_pro
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.eventbus.cast('glicosilada:change', true);
                this.getItemsByEstado();
            }
        });
    }

    closeModalDialog(result: any): void {
        this.dialogRef.close(result);
    }
}
